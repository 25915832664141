//bottom banner

import React from 'react'
import styled from 'styled-components'
import Button from '../../../../../components/General/Buttons/Button'
import SmartLink from '../../../../../components/General/SmartLink'
import { screen } from '../../../../../utils/index'
import tw from 'tailwind.macro'

const Container = styled.div`
  ${tw`
    bg-cover
    xl:w-full
    `}

  background: #5AAF39;
  margin-left: 0px;
  margin-right: 0px;

  ${screen('xl')} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Inner = styled.div`
  ${tw`
  px-2
  lg:px-0
  md:py-4
  md:flex
  py-2
  lg:pb-10
  max-w-2xl
  `}

  margin: 0 auto;
`

const Text = styled.h3`
  ${tw`
    text-white
    font-sans
    leading-tight
    font-extrabold
    text-35px
    pb-4
    pt-4
    lg:pt-7
    lg:pb-5
    md:pl-2
    pl-1
    xl:pl-0
  `}

  ${screen('md')} {
    max-width: 500px;
  }
`

const ButtonContainer = styled.div`
  button {
    border: solid 1px white;
  }
`

function BottomBanner({ text, cta, background }) {
  return (
    <Container background={background}>
      <Inner>
        <Text>{text}</Text>
        <SmartLink
          to={cta ? cta.url : null}
          className="no-underline md:my-auto md:pl-5"
        >
          <ButtonContainer>
            <Button>{cta ? cta.text : null}</Button>
          </ButtonContainer>
        </SmartLink>
      </Inner>
    </Container>
  )
}

export default BottomBanner
