import React from 'react'
import classNames from 'classnames'
import ArrowRight from '../../Shapes/ArrowRight'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const ButtonText = styled.div`
  margin-top: 6px;
  a {
    ${tw`
      no-underline
    `}

    &:hover {
      ${tw`
        no-underline
      `}
    }
  }
`

const Button = ({
  children,
  transparent,
  borderedWhite,
  arrowFlushRight,
  textNoShrink,
  isPrimary = false,
  Icon,
  borderedPink,
  ...rest
}) => {
  const baseWrapClasses = `
    flex
    items-center
    justify-center
    cursor-pointer
    font-bold
    ${rest.className}
  `
  const wrapClasses = classNames(baseWrapClasses, {
    'text-white hover:text-black py-1 px-3 bg-green-2 hover:border-black hover:bg-green-2 border border-green-2 transition duration-100 ease-in-out': !transparent,
    'text-white py-1 px-3 bg-transparent hover:bg-magenta border border-white hover:border-white transition duration-100 ease-in-out': transparent,
    'border-2 border-white bg-transparent hover:bg-transparent py-1 px-3': borderedWhite,
    '': !borderedWhite,
    'border-2 text-pink border-pink bg-transparent hover:bg-transparent hover:text-white pl-4':
      borderedPink && !borderedWhite,
  })

  const baseTextClasses = `
    mr-3
    font-sans
    leading-none
    uppercase
  `

  const textClasses = classNames(baseTextClasses, {
    'text-lg tracking-wide': isPrimary,
    'text-sm': !isPrimary && !transparent,
    'bg-transparent': transparent,
    'flex-no-shrink': textNoShrink,
  })

  const baseArrowClasses = `
    flex
    flex-no-shrink
  `

  const arrowClasses = classNames(baseArrowClasses, {
    'ml-auto': arrowFlushRight,
  })

  return (
    <button className={wrapClasses}>
      <ButtonText className={textClasses}>{children}</ButtonText>
      {Icon ? (
        <div className="flex flex-no-shrink">
          <Icon />
        </div>
      ) : (
        <div className={arrowClasses}>
          <ArrowRight />
        </div>
      )}
    </button>
  )
}

export default Button
